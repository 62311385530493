.site_alert {
	> .alert:first-of-type { margin-top: 1em; }
	> .alert:last-of-type { margin-bottom: 0; }
}

@mixin alert($bg: #ddd, $color: $color-text) {
	border: 1px solid darken($bg, 10%);
	background-color: lighten($bg, 40%);
	color: $color;
	strong, a { color: darken($bg, 15%); }
}

.alert {
	@include alert();
	margin-bottom: 1em;
	padding: 1em;
	border-radius: 3px;
	strong { font-weight: 800; }
}

	.alert-primary { @include alert($color-primary); }
	.alert-secondary { @include alert($color-secondary); }
	.alert-highlight { @include alert($color-highlight); }
	.alert-accent { @include alert($color-accent); }
	.alert-success { @include alert($color-success); }
	.alert-danger { @include alert($color-danger); }
	.alert-info { @include alert($color-info); }
	.alert-warning { @include alert($color-warning); }
	.alert-link { @include alert($color-link); }
