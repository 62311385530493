body {

	min-width: 360px;
	background-color: $body-bg;
	height: 100%;
	min-height: 100%;
	padding-bottom: 71px;

	.site_info {
		position: absolute;
		bottom: 0;
		width: 100%;
	}
		
}

#directions,
#hours {
	outline: none !important;
	box-shadow: none !important;
}