.block.banner {
	padding: 30px 0 0;
	text-align: center;
	border-bottom: 3px solid $white;
	background-image: linear-gradient(
		to top,
		theme(highlight, dark) 20px,
		theme(highlight, base) 20px,
		theme(highlight, base) 39px,
		#c0c0c0 39px,
		#f5f5f5
	);


	@include media-breakpoint-up(md) {
		padding-top: 45px;
		.logo small { padding: 10px; }
	}

}