.front_jumbo {

	padding: 30px 15px;
	@include font-size(18);
	font-weight: $body-weight-bold;
	text-shadow: 0 1px 0 rgba(0,0,0,0.25);
	background:   	linear-gradient(45deg, rgba(0,0,0,0.1) 0%, transparent 25%, transparent 75%, rgba(0,0,0,0.3) 100%),
				  	linear-gradient(135deg, rgba(0,0,0,0.3) 0%, transparent 25%, transparent 75%, rgba(0,0,0,0.1) 100%),
				  	linear-gradient(135deg, rgba($white,0) 15%, rgba($white,0.3) 40%, rgba($white,0.3) 60%, rgba($white,0) 85%),
					linear-gradient(45deg, theme(primary, base), theme(secondary, base));
	color: $white;

	@include media-breakpoint-up(md) {
		@include font-size(20);
		.list > li:not(:last-child) { margin-bottom: 10px; }
	}

	@include media-breakpoint-up(lg) {
		.jumbo_marketing { padding-right: 30px; margin-bottom: 0; }
		.jumbo_conversion { padding-left: 5px; }
	}

}

	.jumbo_marketing {
		margin-bottom: 20px;
	}

		.marketing-title {
			margin-top: 0;
			@include font-size(30);
			font-weight: $heading-weight-bolder;
		}

	.jumbo_conversion {}