.gallery {
    margin-bottom: 1em;
}

    .gallery_sidebar {
        img {
            display: block;
            width: 100%;
            max-width: 360px;
            margin: 0 auto 0.5em;
        }
    }

    .gallery_lightbox {
        img {
            padding: 4px;
            border: 1px solid #ccc;
            background-color: $white;
        }
        > a { margin-bottom: 1%; }
    }

    .g2-sm {
        @include media-breakpoint-only(sm) {
            > img, > a {
                display: block;
                float: left;
                width: 49.5%;
                &:nth-child(odd) { clear: both; }
                &:nth-child(even) { float: right; }
            }
        }
    }

    .g3-md {
        @include media-breakpoint-only(md) {
            > img, > a {
                display: block;
                float: left;
                width: ((100 - (3 - 1)) / 3) * 1%;
                margin-right: 1%;
                &:nth-child(3n-2) { clear: both; }
                &:nth-child(3n) { float: right; margin-right: 0; }
            }
        }
    }

    .g2-lg {
        @include media-breakpoint-only(lg) {
            > img, > a {
                display: block;
                float: left;
                width: 49.5%;
                &:nth-child(odd) { clear: both; }
                &:nth-child(even) { float: right; }
            }
        }
    }

    .g3-xl {
        @include media-breakpoint-up(xl) {
            > img, > a {
                display: block;
                float: left;
                width: ((100 - (3 - 1)) / 3) * 1%;
                margin-right: 1%;
                &:nth-child(3n-2) { clear: both; }
                &:nth-child(3n) { float: right; margin-right: 0; }
            }
        }
    }