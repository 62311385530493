.company_block {

	text-align: center;

	@include media-breakpoint-only(md) {
		.company_t {
			@include make-flex;
			align-items: center;
			margin-bottom: 10px;
			.company_logo,
			.company_call {
				width: 50%;
				margin: 0;
			}
		}
		.list-company {
			li { display: inline-block; margin-right: 5px; }
		}
	}

	@include media-breakpoint-up(lg) {
		.gallery img {
			display: block;
			width: 100%;
		}
	}


}

	.company_logo {
		display: block;
		width: 100%;
		max-width: 360px;
		margin: 0 auto;
	}

	.company_call {
		line-height: 1em;
		.phone {
			@include font-size(24);
			font-weight: $body-weight-bolder;
			color: $color-text;
		}
		.call_emergency {
			display: block;
			margin-top: 5px;
			@include font-size(15);
			font-weight: $body-weight-bold;
			text-transform: uppercase;
			letter-spacing: -0.0325em;
			color: theme(danger, base);
		}
	}

	.company_address {
		line-height: 1.275em;
	}

	.list-company {
		margin-bottom: 20px;
		@include font-size(15);
		font-weight: $body-weight-bold;
		li { margin-bottom: 7px; }
		span {
			display: block;
			padding: 3px 15px;
			text-shadow: 0 1px 0 rgba(0,0,0,0.35);
			background-color: theme(primary, base);
			background: linear-gradient(to right, theme(primary, opac), theme(secondary, opac));
			color: $white;
		}
	}