.sidebar {

	@include media-breakpoint-down(md) {
		padding-top: 15px;
	}

	@include media-breakpoint-up(lg) {
		.form_submit input { display: block; width: 100%; }
	}

	@include media-breakpoint-only(lg) {
		.form_spam {
			position: relative;
			overflow: hidden;
			height: 75px;
			.g-recaptcha {
				@include transform(scale(0.85));
				position: absolute;
				top: 0;
				left: -8px;
			}
		}		
	}

	> * { margin-bottom: 15px; }

}