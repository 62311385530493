$form_focus_border: 			$yellow;
$form_focus_bg: 				lighten($yellow, 40%);
$placeholder-color: 		rgba($white, 0.9);
$placeholder-hover: 		rgba(black, 0.3);

.form {

	@include font-size(15);
	font-family: $font-family-sans-serif;
	line-height: 1em;

}

	.form-title {
		margin-top: 0;
	}

	.form_group {
		margin: 0 0 8px;
		padding: 0;
		&.form_hp { display: none; }
		&.form_clear { clear: both; }
		&.form_submit { margin-bottom: 0; }
	}

		label {
			display: block;
			margin: 0 0 1px 2px;
			font-size: 0.9rem;
			font-weight: $body-weight-bold;
			line-height: 1em;
			letter-spacing: -0.0275em;
		}

		.form-control {
			display: block;
			width: 100%;
			margin: 0 0 5px;
			padding: 9px 7px;
			font-weight: $body-weight-bold;
			border-radius: 3px;
			border: 1px solid #ddd;
			background-color: $white;
			outline: none;
			&:focus {
				border-color: $form_focus_border;
				background-color: $form_focus_bg;
			}
		}

			textarea.form-control {
				min-height: 150px;
				line-height: 1.275em;
			}


	.form_error .form_control {
		border-radius: 2px 2px 0 0;
		border-color: $color-danger !important;
	}

		.error-message {
			margin: -5px 0 0;
			padding: 6px 4px 4px;
			font-size: 0.7em;
			font-weight: $body-weight-bolder;
			line-height: 1em;
			text-transform: uppercase;
			background-color: theme(danger, base);
			color: $white;
		}

	.form_spam {
		position: relative;
		overflow: hidden;
		//border-radius: 20px;
		height: 65px;
		width: 255px;
		background-color: #dcdcdc;
		.g-recaptcha {
			position: absolute;
			top: -6px; left: -24px;
			transform: scale(0.85);
		}
	}