.form_quote {

	padding: 15px;
	border: 3px solid $white;
	background-color: rgba($white, 0.5);

	.form_submit .btn {
		transform: scale(1);
	}

}