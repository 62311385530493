.copyright_data {

	ul > li { line-height: 1.1em; }

	a {
		color: $white;
		font-weight: 600;
		text-decoration: none;
	}

	@include media-breakpoint-up(sm) {

		ul > li { 
			display: inline-block;
			&:not(:last-child) { margin-right: 0.75em; }
		}

	}

}
