.coverage {

	padding: 1em;
	border: 1px solid #ddd;
	background-color: $white;

	> *:first-child { margin-top: 0; }
	> *:last-child { margin-bottom: 0; }

}

	.coverage_title {
		margin-bottom: 0.5em;
		font-size: 17px;
		font-weight: 800;
	}

		.coverage_city {
			font-size: 14px;
			line-height: 1.25em;
		}
