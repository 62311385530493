.block {
	padding: 30px 0;
}

.city_menu {

	padding: 15px;

	.city-menu-title {
		margin-top: 0;
		@include font-size(18);
	}

	ul > li > a[href="#"] {
		border-color: #555 !important;
		background-color: #777 !important;
		transform: scale(0.9);
		&:hover {
			transform: scale(0.9);
			cursor: default;
		}
	}

}