%clearfix {
	*zoom: 1;
	&:before, &:after {
		content: " ";
		display: table;
	}
	&:after {
		clear: both;
	}
}

%card {
	padding: 0.5em;
	border: 1px solid #ddd;
	background-color: $white;
	box-shadow: 0 2px 4px 0 rgba(0,0,0,0.1);
}

%gdfancy-primary-bg { @include gdfancy-bg($color-primary); }

%gdfancy-secondary-bg { @include gdfancy-bg($color-secondary); }

%gdfancy-accent-bg { @include gdfancy-bg($color-accent); }

%gdfancy-highlight-bg { @include gdfancy-bg($color-highlight); }

%gdfancy-success-bg { @include gdfancy-bg($color-success); }

%gdfancy-danger-bg { @include gdfancy-bg($color-danger); }

%gdfancy-warning-bg { @include gdfancy-bg($color-warning); }

%gdfancy-info-bg { @include gdfancy-bg($color-info); }
