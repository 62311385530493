.block.page-title {
	padding: 45px 0;
	color: $white;
	border-bottom: 5px solid $white;
	text-shadow: 0 1px 2px rgba(0,0,0,0.4);
	background:   	linear-gradient(45deg, rgba(0,0,0,0.1) 0%, transparent 25%, transparent 75%, rgba(0,0,0,0.3) 100%),
				  	linear-gradient(135deg, rgba(0,0,0,0.3) 0%, transparent 25%, transparent 75%, rgba(0,0,0,0.1) 100%),
				  	linear-gradient(135deg, rgba($white,0) 15%, rgba($white,0.3) 40%, rgba($white,0.3) 60%, rgba($white,0) 85%),
					linear-gradient(45deg, theme(primary, base), theme(secondary, base));

	h1 {
		margin: 0;
		@include font-size(20);
		font-weight: $heading-weight-bolder;
		line-height: 1em;
		text-align: center;
	}
	
	@include media-breakpoint-up(md) {
		padding: 120px 0 15px;
		h1 { @include font-size(24); }
	}
	
	@include media-breakpoint-up(lg) {
		h1 { text-align: left; }
	}

	@include media-breakpoint-up(xl) {
		h1 { @include font-size(30); }
	}

}